
#createEstablishment > .table-responsive {
    height: 15vh !important;
}

#rolesTable > .table-responsive {
    height: 100% !important;
    max-height: calc(100vh - 250px) !important;
    border-radius: 0px !important;
}

#userTable > .table-responsive {
    height: 100% !important;
    max-height: calc(100vh - 240px) !important;
    border-radius: 0px !important;
}

#roleUsersTable > .table-responsive {
    height: 100% !important;
    max-height: calc(100vh - 420px) !important;
}

#healthboardTable-transform > .table-responsive {
    height: 100% !important;
    max-height: calc(100vh - 230px) !important;
    white-space: nowrap;
}

#establishmentsTable > .table-responsive {
    height: 100% !important;
    max-height: calc(100vh - 195px) !important;
}

#userTypeTable > .table-responsive {
    height: 100% !important;
    max-height: calc(100vh - 110px) !important;
}

#userTypesTable > .table-responsive {
    height: 100% !important;
    max-height: calc(100vh - 110px) !important;
}

#listView-data-rawxml > .table-responsive {
    height: 100% !important;
    max-height: calc(100vh - 273px) !important;
    white-space: nowrap;
}

#listView-data > .table-responsive {
    height: 100% !important;
    max-height: calc(100vh - 312px) !important;
    white-space: nowrap;
}

.child-establishments > .table-responsive {
    height: 100% !important;
    max-height: calc(100vh - 165px) !important;
}

#extractClientSetup > .table-responsive {
    height: 100% !important;
    max-height: calc(100vh - 110px) !important;
}

#userTypesForExtract {
    height: auto !important;

    .extract-client-user-types > .table-responsive {
        height: 100% !important;
        max-height: calc(100vh - 480px) !important;
    }
}

.role-users .table-responsive {
    height: 45vh !important;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.table-responsive {
    height: 70vh;
    background-color: $white;
}

.modal-body .table-responsive {
    height: 60vh;
}

input[type=search]::-webkit-search-cancel-button {
    cursor: pointer;
}

#userTable > .table-title {
    max-height: 18vh !important;
}

#rolesTable > .table-title {
    max-height: 30vh !important;
}

.table-title {
    min-height: 6vh !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
}

.form-check-input {
    width: 20px !important;
    height: 20px !important;
}

.form-check-input:disabled {
    opacity: 1 !important;
}

.form-check-input:checked {
    background-color: $crbblue !important;
}

#establishmentFilterTable > .table-responsive td:first-child {
    width: 1%;
}

.parent-consent-td {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 10px;
    cursor: pointer;
}

.establishment-details .react-loading-skeleton {
    --base-color: #d5e2f6 !important;
    --highlight-color: #eaf1fb !important;
}

.extract-client-user-types > .table-responsive {
    height: 30vh !important;
}

.role-details .react-loading-skeleton {
    --base-color: #d5e2f6 !important;
    --highlight-color: #eaf1fb !important;
}