.mainErrorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: #f0f0f0;
  font-family: Arial, sans-serif;
}
.error-container {
  text-align: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.error-code {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #e74c3c;
}
.error-message {
  font-size: 18px;
  color: #333;
}
.err-back-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #3498db;
  color: #fff !important;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s;
}
.err-back-button:hover {
  background-color: #2980b9;
}
