.mobile-nav {
    background-color: $nav-bg-color;

    .navbar {
        background-color: $nav-bg-color !important;
    }

    .navbar-brand {
        color: $white;
    }
    
    .navbar {
        h3 {
            margin-top: 1rem;
            margin-bottom: 1rem;
            color: $white;
        }

        .sidebar-list-group {
            margin-bottom: 0;
            list-style: none;
            padding-left: 0;

            .sidebar-list-group-item-container {
                width: 140px;
                float: left;
            }

            .sidebar-list-group-item {
                .sidebar-list-group-item-link {
                    color: $white;
                    font-weight: 400;
                    font-size: 1.1rem;

                    .side-bar-icon {
                        width: 50px;
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .navbar-text {
            color: $white;
            font-weight: bold;
            font-size: $font-size-lg;
            text-decoration: underline;
        }

        &>a {
            color: $white;
            margin-left: 1rem;
        }

        .navbar-toggler {
            border: 1px solid $crbblue;
            margin-right: 10px;
        }
    }
}