.filter {
 position: relative;
 display: inline-block;
}

.filter__button {
 border-radius: 0.125rem;
 padding: 0.5rem 1rem;
 background-color: #2b7de9;
 background-image: none;
 cursor: pointer;
 border: transparent;
 font-weight: 600;
 color: white;
 font-size: 16px;
 line-height: 1.5;
}

.filter__button:hover {
 background-color: #176dde;
}
.filter__button:focus {
 outline: 1px dotted;
 outline: 1px auto -webkit-focus-ring-color;
}

.filter__dropdown {
     box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    padding: 0.25rem;
    width: 16rem;
    z-index: 50;
    max-height: calc(100vh - 130px);
    position: absolute;
    margin-top: 10rem;
    background-color: white;
}

.filter-option{
    height: 30px;
    padding-left: 5px;
}

.filter-option:hover{
    cursor: pointer;
    background-color: #f8f9fa!important;
    color: #000!important;
}

.dropdown-filter::after {
    display: none !important; 
}

.selected-filter-color{
    background-color: $filter-bg-color !important;
    color: $filter-color !important;
    font-weight: 500 !important;
}

// timer css start
.timer-dropdown .dropdown-item {

    color: $black !important;
    background-color: $white !important;
    cursor: pointer;
    white-space: nowrap;
}
.timer-dropdown .dropdown-item:hover,
.timer-dropdown .dropdown-item.active {
    color: $black !important;
    background-color: #f8f9fa !important;
}
.timer-dropdown .dropdown-item:not(:nth-last-of-type(1)) {
    border-bottom: 2px solid #EAECF0 !important;
}
.timer-dropdown .dropdown-item {
    font-size: 14px;
    margin-top: 3px;
    margin-bottom: 3px;
}
.timer-toolbar-button {
    display: inline-block;
    padding: 3px 12px;
    vertical-align: middle;
    overflow: hidden;
    color: $black !important;
    text-decoration: none;
    background-color: $white !important;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    border: 1px solid #ccc !important;
    border-radius: 5px;
    height: auto;
    width: auto;
    font-family: Inter;
    font-size: 14px !important;
    font-style: normal;
    line-height: 20px;

    border-radius: 8px;
    border: 1px solid var(--gray-300, #D0D5DD);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.timer-toolbar-refresh-button {
    color: var(--gray-500, #667085) !important;
}
.timer-toolbar-button:hover,
.timer-toolbar-button.active {
    color: $black !important;
    background-color: #f8f9fa !important;
}
#timer-dropdown-id {
    color: $black !important;
    background-color: $white !important;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    border: 1px solid #ccc !important;
}
#timer-dropdown-id:hover {
    color: $black !important;
    background-color: $white !important;
    border: 1px solid #ccc !important;
}
#timer-dropdown-id:after {
    content: none !important
}
.text-filter-top{
    margin-top: -2.75rem !important;
}
// timer css end