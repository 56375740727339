// Colours

$crbblue: #1B56A5; //#005085;
$crbgray: #5D6E8C;
$fontcolor: #667085;
$fontcolor-active: #0060DF;
$heading-fontcolor: #48505E;
$white: #fff;
$black: #000;
$background-color: #f9fbfd;
$nav-bg-color: #f8f9fa;
$nav-avtive-color: #1e56a0;
$nav-avtive-bg-color: #e0edff;
$body-bg-color: #f9fbfd;
$body-color: #212529;
$logo-bg-color: #d0d5dd;
$border-color: #EAECF0;
$icon-color: #094Daf;
$green-bg-color: #12B76A;
$green-icon-color: #027A48;
$red-bg-color: #FEE4E2;
$red-icon-color:#D92D20;
$amber-bg-color: #FFF2CC;
$amber-icon-color: #FFBF00;
$package-icon-color: #e7f0fe;
$green-status-color: #ECFDF3;
$dark-green-status-color: #12B76A;
$green-text-color: #027A48;
$gray-status-color: #F2F4F7;
$gray-text-color: #344054;
$orange-icon-color: #E67E22;
$log-error-color:#DF4115;
$log-warning-color:#B54708;
$log-critical-color:#B42318;
$log-error-bg-color:#FEF6EE;
$log-warning-bg-color:#FFFAEB;
$log-critical-bg--color:#fef2f2;
$log-information-bg--color:#f1faf5;
$log-debug-bg--color:#ebf9ff;

$dot-error-color:#EF6820;

$dot-warning-color:#F79009;

$dot-critical-color:#F04438;
$theme-light-blue : #EEF3FB;
$table-font-color : var(--gray-900, #101828);
$filter-bg-color: #DEE8F7;
$filter-color: #4F6D96;

// Font
$font-family: "Inter";
$font-size: 0.9375rem;
$font-size-sm: 0.75 * $font-size;
$font-size-lg: 1.25 * $font-size;
