.heading {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 8px !important;
}

.header-icon{
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    width: 48px;
    height: 48px;
}

.blue-icon {    
    background: #c7dfff;
    border: 6px solid #e0edff;
}

.light-green-icon {
    background: #d1fadf;
    border: 6px solid #ECFDF3;
}

.green-icon {
    background: $green-bg-color;
    border: 6px solid #ECFDF3;
}

.orange-icon {    
    background: #FEF0C7;
    border: 6px solid #FFFAEB;
}

.error-icon {
    background: #FEE4E2;
    border: 6px solid #FEF3F2;
}

.warning-icon{
    background: #FEF0C7;
    border: 6px solid #FFFAEB;
}