.title-text {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: #101828;
  font-family: $font-family;
}
.desc-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #667085;
  margin-left: 12px;
  font-family: $font-family;
}
.label-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #344054;
  margin-left: 12px;
  font-family: $font-family;
}
.field-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #101828;
  font-family: $font-family;
}
.popup-btn {
  padding: 10px 18px 10px 18px;
  width: 170px;
  height: 44px;
}
.margin24 {
  margin: 24px;
}
.right-margin10 {
  margin-right: 10px;
}
.right-margin24 {
  margin-right: 24px;
}
.float-right {
  float: right;
}
.display-content {
  display: contents;
}
.popup-model {
  height: 338px;
  width: 400px !important;
}
.succes-popup {
  display: flex;
  padding: 6px;
  background: #f6fef9;
  border-radius: 5px;
  border: 1px solid #6ce9a6;
  margin: -17px;
}
.succes-text {
  margin: auto;
  font-size: 12px;
  font-family: "Inter";
  color: #027a48;
}
.error-popup {
  display: flex;
  padding: 6px;
  background: #fffbfa;
  border-radius: 5px;
  border: 1px solid #fda29b;
  margin: -17px;
}
.error-text {
  margin: auto;
  font-size: 12px;
  font-family: "Inter";
  color: #b42318;
}
.margin-top20 {
  margin-top: 20px;
}
.padding0 {
  padding: 0px;
}
.invalid {
  padding-left: 10px;
  color: #dc3545;
}
.margin-left10 {
  margin-left: 10px;
}
.title-heading {
  font-size: 20px;
  font-weight: 500;
  line-height: 34px;
  color: #344054;
  margin-left: 12px;
  font-family: "Inter";
}
.btn-create {
  height: 44px;
  width: 30%;
  left: 0px;
  top: 0px;
  border-radius: 8px;
  padding: 10px 18px 10px 18px;
}
.text-wrap-next-line{
  white-space: pre-wrap
}

.btn-small {
  padding: 0px !important;
  width: 100px;
  height: 35px;
}